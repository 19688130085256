<template>
  <a-modal title="编辑" :width="500" :visible="visible" :confirmLoading="confirmLoading" :destroyOnClose="true"
    @ok="handleSubmit" @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 产币 -->
            <a-form-item label="产币" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number placeholder="请输入" :min="0" :max="999999999" :precision="0"
                style="width: 50%;min-width: 100px"
                v-decorator="['coin', { rules: [{ required: true, message: '请输入！' }] }]" />
            </a-form-item>
            <!-- 价格 -->
            <a-form-item label="价格" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number placeholder="请输入" :min="0" :max="999999999" :precision="2"
                style="width: 50%;min-width: 100px"
                v-decorator="['price', { rules: [{ required: true, message: '请输入！' }] }]" />
            </a-form-item>
            <a-form-item label="机型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select v-decorator="['phoneType', { rules: [{ required: true, message: '请选择' }] }]"  allow-clear placeholder="请选择">
                <a-select-option :value="1">安卓</a-select-option>
                <a-select-option :value="2">IOS</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import {
  rechargeCoinDetail,
  rechargeCoinEdit

} from '@/api/modular/mallLiving/rechargeCoin'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      id: '',
    }
  },

  methods: {
    // 初始化方法
    edit(record) {
      this.visible = true
      this.confirmLoading = true
      this.id = record.id
      //调取详情接口，回显数据
      setTimeout(() => {
        rechargeCoinDetail({ id: this.id }).then((res) => {
          if (res.success) {
            var details = res.data
            this.picture = details.logo
            this.form.setFieldsValue({
              coin: details.coin,
              price: details.price,
              phoneType: details.phoneType
            })
            this.confirmLoading = false
          }
        })
      }, 200)
    },
    //提交操作
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          rechargeCoinEdit({ id: this.id, ...values }).then((res) => {
            if (res.success) {
              this.$message.success("编辑成功！")
              this.$emit("ok", values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>