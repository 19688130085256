//订单管理相关接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-03-16
 */

//充值包分页查询
export function rechargeCoinPageList(parameter){
    return axios({
        url: '/rechargeCoin/page',
        method: 'get',
        params:parameter.page,
        // data: parameter.queryParam
    })
}
//充值包详情
export function rechargeCoinDetail(parameter){
    return axios({
        url: '/rechargeCoin/detail',
        method: 'get',
        params: parameter
    })
}
//充值包新增
export function rechargeCoinAdd(parameter){
    return axios({
        url: '/rechargeCoin/add',
        method: 'post',
        data: parameter
    })
}
//充值包编辑
export function rechargeCoinEdit(parameter){
    return axios({
        url: '/rechargeCoin/edit',
        method: 'post',
        data: parameter
    })
}
//充值包修改状态
export function rechargeCoinDelete(parameter){
    return axios({
        url: '/rechargeCoin/delete',
        method: 'post',
        data: parameter
    })
}